import * as utilities from "./utilities";

const init = function () {

    // Provide custom timeout value for curbside puckup page
    const _LOCATION = window.location,
          _PATH = _LOCATION.pathname,
          _HOST = _LOCATION.host,          
          _isHP = _PATH === ( "/" ),
          _isDEV = ( _HOST.indexOf( "homedepotdev.com" ) > -1 || _HOST.indexOf( "localhost.homedepot.com" ) > -1 );          
                
    /* eslint-disable */

    // Handle SPA Transitions	
	(function() {

        let count = 0,
            limit = 10,
            _prevURL = _PATH,
            _sskuClick = false;
            
		const _suppressMBox = function ( prev, current ) {

			// plp should not fire mbox on single page change of plp to plp
			// which is essentially if last page is /b/* or /s/* and incoming page is /b/*
			let suppressPLP = ( (prev.indexOf( "/b/" ) !== -1 || prev.indexOf( "/s/" ) !== -1) && ( current.indexOf( "/b/" ) !== -1 || current.indexOf( "/s/" ) !== -1 )),
				// should not fire mbox on PIP during Super SKU interaction
				suppressSSKU = _sskuClick;

            //reset after reading value    
            _sskuClick = false;

			return ( suppressPLP || suppressSSKU );

        };
        
        const _fireMBoxOnLocationChange = function ( opts ){                                          
            let _curr = "";

            if ( opts && opts.output && opts.output.location ) {

                _curr = ( opts.output.location.pathname ? opts.output.location.pathname : "" );
                
            }

            if ( _prevURL !== _curr && !_suppressMBox( _prevURL, _curr )) {

                waitOrExecute();

            }
            
            _prevURL = _curr;

        };
			
        let inter = setInterval( function() {

            count++;
            let listenerSet = false;

            if ( typeof window.LIFE_CYCLE_EVENT_BUS === 'undefined' && count <= limit ) return;
			console.log( LOG_PREFIX + " -- LIFE_CYCLE_EVENT_BUS Found");
            
            if ( window.LIFE_CYCLE_EVENT_BUS && typeof LIFE_CYCLE_EVENT_BUS.on === 'function' ) {

                listenerSet = true;

				console.log( LOG_PREFIX + " -- ROUTER CHANGE LISTENER SET");

                window.LIFE_CYCLE_EVENT_BUS.on( 'router.change', function ( opts) {

					console.log( LOG_PREFIX + " -- ROUTER CHANGE LISTENER FIRED");
                    _fireMBoxOnLocationChange( opts );

                });

                window.LIFE_CYCLE_EVENT_BUS.on("super-sku.change", function ( opts ) {

					console.log( LOG_PREFIX + " -- SSKU CHANGE LISTENER FIRED", opts);
                    _sskuClick = true;

                });
            }
            
            if ( listenerSet || count >= limit ) {
				console.log( LOG_PREFIX + " -- LIFE_CYCLE_EVENT_BUS INTERVAL CLEARED", listenerSet, count, limit);
                clearInterval( inter );
            }

        }, 100, count, limit );

	}());

    const getSKUFromURL = ( path = window.location.pathname ) => {                       
        const urlParts = path.split( "/" );                                      
        return ( urlParts.length ?  urlParts[ urlParts.length - 1 ] : "" );        
    }
    
    const handleResponseTokens = ( result, options = {"ignoreRenderCheck": false} ) => {

        function isUnique(arr, key, val) {

            for (let i = 0; i < arr.length; i++) {

                if (arr[i][key] === val) return false;

            }

            return true; 
        }
        
        function isObjEmpty (obj) {

            return Object.keys(obj).length === 0;

        }

		console.log( LOG_PREFIX + " -- handleResponseTokens - result.propositions: ", result.propositions );

        result.propositions.forEach(function(proposition) {

            //Capture decisioning tokens to send to analytics
            if ( options.ignoreRenderCheck && isUnique( window._tt.TDO.propositions, 'id', proposition['id']) ) {

                window._tt.TDO.addProposition({ id: proposition.id, scope: proposition.scope, scopeDetails: proposition.scopeDetails });

            }

            if ( proposition.renderAttempted || options.ignoreRenderCheck ) { // TODO: check docs for custom scopes

                proposition.items.forEach(function(item) {

                    const token = item.meta; 
                    
                    if ( typeof ( token ) === "object" && !isObjEmpty( token ) ) { //contains the response tokens

                        if (isUnique( window._tt.META, 'ActivityID', token['activity.id'])) {

                            window._tt.META.push({
                                'ActivityName': token['activity.name'],
                                'ActivityID': token['activity.id'],
                                'Experience': token['experience.name'],
                                'OfferName': token["option.name"]
                            });
                        }                        
                    }
                });
            }
        });

    };

    const setCustomTargetParams = () => { 
        
        let params = {},            
			hddc = utilities.readCookie( 'HD_DC' ),
            storeNumber = utilities.getStoreNumber(),        			
            entityid= "",
            isOrderConfirmation = ['/order-confirmation','/mycheckout/thankyou'].some( val => _PATH.indexOf( val ) !== -1 ),
            metaDataSource = ( window.top.experienceMetadata ? window.top.experienceMetadata : {} ),            
            mboxPageType = (() => {

                let allowedHosts = ['secure2.homedepot.com','www.homedepot.com','homedepotdev.com'],
                    determinePageType = () => {
                        
                        let pattern, _i,
                            targetURLs = [
                                ['\/b\/', 'Browse_PLP'],
                                ['\/s\/', 'Search_PLP'],            
                                ['\/mycart\/', 'Cart'], 
                                ['\/cart', 'Cart'],             
                                ['\/opc\/signin\.html', 'Sign-in'],            
                                ['\/c\/', 'Category_Page'],
                                ['\/p\/', 'PIP'],
                                ['\/pep\/', 'PEP']
                            ],
                            _len = targetURLs.length;
                                    
                        for ( _i = 0; _i < _len; _i++ ) {
                            
                            pattern = targetURLs[ _i ][ 0 ];
                                   
                            if ( _LOCATION.href.indexOf( pattern ) > -1 ) {
                                
                                return targetURLs[ _i ][ 1 ];                            
                                
                            }
                
                        }
            
                        return null;
            
                    }; 
                            
                // exit early if not allowed host
                if ( !allowedHosts.some(host => _HOST.includes(host)))  { return null; }
                    
                if ( _isHP || ( _HOST === "secure2.homedepot.com" && _PATH.indexOf('/b2b/account/view/homepage') > -1 ) ) {
                    
                    return 'Homepage';
                            
                }
                
                  // Handle ORDER CONFIRMATION                           
                if ( isOrderConfirmation ){
                
                    return 'Order_Confirmation';

                } 

                    
                return determinePageType( );
                    
            })(),
            apolloState = {},
            apolloStateKey = "",
            apolloStateInfo = {},
            apolloStateTaxonomy = {};

		params.authenticatedUsers = ( _aape.svocid ? true : false ) ;        
		params.deviceType = utilities.getDeviceType();
        params.isWebView= ( window.navigator.userAgent.toLowerCase().indexOf("thdconsumer/") !== -1 );
        params.siteBusinessType = utilities.getBusinessType();

        if ( utilities.isPIP() || utilities.isPEP() ) {

        	entityid = getSKUFromURL();

        	if ( entityid ) {
                
        		params["entity.id"] = entityid;

                if ( utilities.isPIP() ) {

                    apolloState =  ( window.top?.__APOLLO_STATE__ ?? {} );
                    apolloStateKey = `base-catalog-${entityid}`;

                    if ( apolloStateKey in apolloState ) { 

                        apolloStateInfo = ( apolloState[apolloStateKey].info ?? {} );
                        apolloStateTaxonomy = ( apolloState[apolloStateKey].taxonomy ?? {} );

                        params["product.department"] = apolloStateInfo?.productDepartment ?? 0;
                        params["product.class"]  = apolloStateInfo?.classNumber ?? 0;

                        if ( typeof ( apolloStateInfo?.subClassNumber ) !== "undefined" ) {

                            params["product.subclass"] = apolloStateInfo.subClassNumber; 
                                        
                        }

                        if ( Array.isArray( apolloStateTaxonomy?.breadCrumbs ) && apolloStateTaxonomy.breadCrumbs.length ){

                            params["product.taxonomy.category"] = apolloStateTaxonomy.breadCrumbs[0]?.label ?? "null";
                            
                        }
                    }
                }
               

        	}

        }
        
        if ( hddc ) {

            params.HD_DC = hddc;

        }

		if ( mboxPageType ) {

			params.pageType = mboxPageType;

		}

        if ( storeNumber !== "" ) {

            params.storeNumber = storeNumber;

        }

		if ( metaDataSource.name ) {

            params.experienceName = metaDataSource.name;
            
        }

        return params;

    };

    const sendTargetEvent = ( params = setCustomTargetParams() ) => {

        let identityXDM = ((id, persisted) => {

            if (!id || typeof ( persisted ) === "undefined" ) { return {}; }

            return {
                "identityMap": {
                    "svocid": [
                    {
                        "id": id,
                        "authenticatedState": persisted ? "ambiguous" : "authenticated",
                        "primary": true
                    }]
                }
            };

		} )( _aape.svocid, _aape.isUserPersisted );

		// type: Set this parameter to decisioning.propositionFetch. This special event type tells Adobe Analytics to drop this event. When using Customer Journey Analytics, you can also set up a filter to drop these events.
		// https://experienceleague.adobe.com/en/docs/experience-platform/web-sdk/use-cases/top-bottom-page-events#top-of-page

        alloy("sendEvent", {
			type: "decisioning.propositionFetch",
            renderDecisions: true,
			personalization: {
				sendDisplayEvent: utilities.isOverlay() ? true : false
			},
            xdm: identityXDM,
            data: {
                __adobe: {
                    // pass custom params to Target
                    target: params
                }                    
            }
        }).then(function(result) {

            handleResponseTokens(result);

        }).catch(function (error) {            
            // The command failed.
            console.log(LOG_PREFIX + " -- ALLOY sendEvent ERROR: ", error);            
        });

    };

    const getDecisionScopesByName = ( names = [], options = {"enablePropertyOverride": true, "params": {}}) => {
        
        if ( !Array.isArray( names ) ) { return; }

        let configObject = {
            type: "decisioning.propositionFetch", 
            renderDecisions: false,			        
            decisionScopes: names,
            data: {
             __adobe: {              
                target: {...setCustomTargetParams(), ...options.params}
             }
            }
        };
        
        if ( options.enablePropertyOverride ) {

            // This value must be set for Campaigns created for Target/ComponentSelector/Contentful Integration to work in DEV           
            if ( _isDEV ) {    

                configObject['edgeConfigOverrides'] = { "com_adobe_target": { "propertyToken": "2f8bc98b-1956-a34c-2496-11f9b8eab162" } }; 
            }

        } 

        return alloy("sendEvent", configObject)
        .then(function (result) {

            //Ensures we update _tt.META
            handleResponseTokens(result, { "ignoreRenderCheck": true });

            return result;

        }).catch(function (error) {            
            // The command failed.
            console.log(LOG_PREFIX + " -- ALLOY sendEvent ERROR: ", error);            
        });

    };
    
    const waitOrExecute = () => {
        
        // Call Target Immediately if NOT pn PIP/PEP
        if ( !utilities.isPIP() && !utilities.isPEP() ) {
            
            console.log( LOG_PREFIX + " -- waitOrExecute() | NOT PIP/PEP, fire immediately");  

            sendTargetEvent();
            return;

        }
        
        let apolloState = ( window.top?.__APOLLO_STATE__ ?? {} ),
            apolloStateKey = `base-catalog-${getSKUFromURL()}`;

        // Variable already exists, call callback immediately
        if ( apolloStateKey in apolloState ) {                 
          
          console.log( LOG_PREFIX + " -- waitOrExecute() | OBJECT EXISTS, fire immediately");  
          sendTargetEvent(); 
          
        } else {
          // Wait for the object to be created, then send Target Event
          const observer = new MutationObserver(() => {
            
            console.log( LOG_PREFIX + " -- waitOrExecute() | SETUP MUTATION OBSERVER = ",  window.top?.__APOLLO_STATE__?.[apolloStateKey]); 

            if ( window.top?.__APOLLO_STATE__?.[apolloStateKey] ) {  

              console.log( LOG_PREFIX + " -- waitOrExecute() | OBJECT FOUND, Stop observing");  
              observer.disconnect(); // Stop observing
              sendTargetEvent();

            }

          });
                
          observer.observe(document.body, { attributes: true, childList: true, subtree: true, attributeFilter: [apolloStateKey] }); 
        }

    };

    window.targetPageParamsAll = setCustomTargetParams;
    
    //Externalize for third-party use, for example this is called by ComponentSelector (Contentful/Target Integration)
    _tt.utils.getDecisionScopesByName = getDecisionScopesByName;    

   waitOrExecute();

};


export {
    init
};
